html {
  scroll-behavior: smooth;
}

/*Nav bar style - start*/

.navbar_sh ul li {
  display: flex;
  align-items: center;
  justify-content: center;
padding: 0 10px;

}
.navbar_sh ul li .Link_cls {
  color: #fff;
  text-align: center;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  cursor: pointer;

}
.img_cls_sh{
  cursor: pointer;
}



.whatsapp_sh a {
  color: #34d399;
  text-align: center;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.nav_sh {
 
  background-color: #01041c;
}

/* Navbar link hover effect */
.navbar-nav .nav-link:hover {
  color: #fff !important;
}

/* Navbar link active state */
.navbar-nav .nav-link.active {
  color: #fff !important;
}
.header_shh{
  padding: 10px 0 0px 0;
  
}

/*Nav bar style - completed------------------------------------------*/

/*Home page style  - start------------------------------------------*/

.home_sh h2 {
  color: var(--Tailwind-white, #fff);
  font-family: Cairo;
  font-size: 48px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: uppercase;
  line-height: 1;
}

.home_sh h1 {
  color: var(--Tailwind-white, #fff);
  font-family: Cairo;
  font-size: 88px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.88px;
  line-height: 1;
}

.home_sh p {
  color: var(--Tailwind-white, #fff);
  text-align: center;
  font-family: "Open Sans";
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
}

.get_free_btn {
  width: 100%;
  display: flex;
  padding: 20px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: #ffd700;

  color: var(--Tailwind-slate-950, #020617);
  font-family: "Open Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.2px;
  border: none;
}

.about_btn {
  width: 100%;;
  display: flex;
  padding: 20px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: rgba(29, 29, 29, 0.8);
  backdrop-filter: blur(2px);
  border: none;

  color: var(--Tailwind-white, #fff);
  font-family: "Open Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.2px;
}

.btn_div_sh {
  margin-top: 51px;
  display: flex;
  flex-direction: column; 
}


.home_sh_new {
  padding: 0px 0px 150px 0;
  background: url('../src//assets/home_bg.png') no-repeat center/cover;
  
}

/*Home page style  - end------------------------------------------*/

/*Who we are page style  - start------------------------------------------*/
.whoweare_sh {
  background-color: #fff;
  padding: 80px 0px 50px 0;
  width: 55%;
}

.whoweare_sh h2 {
  color: var(--Tailwind-slate-950, #020617);
  font-family: Cairo;
  font-size: 80px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.8px;
}

.whoweare_sh p {
  color: var(--Tailwind-slate-950, #020617);
  font-family: "Open Sans";
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px;
  margin-bottom: 30px;
}

.whoweare_contact_btn {
  display: inline-flex;
  padding: 15px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  background: var(--Tailwind-slate-950, #020617);
  backdrop-filter: blur(2px);

  color: var(--Tailwind-white, #fff);
  font-family: "Open Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.2px;
  border: none;
}

.explore__image_main_div {
  display: flex;
  flex-wrap: nowrap; /* Ensure images stay in a single row */
  overflow-x: auto; /* Enable horizontal scrolling */
  overflow-y: hidden; /* Prevent vertical scrolling */
  white-space: nowrap; /* Avoid wrapping items */
  gap: 20px; /* Space between images */
  
  padding: 0 0 100px 22.5% ;

  overflow-x: auto;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  
}


.explore__image_main_div::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari, and Opera */
}


.explore__image_main_div .img_div {
  width: 125px;
  height: 125px;
  overflow: hidden;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto; /* Prevent shrinking or growing */
  box-sizing: border-box;
}

.explore__image_main_div .img_div img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/*Who we are page style  - end------------------------------------------*/

/*Our service page style  - start------------------------------------------*/

.scroll_container {
  padding: 1rem 8% 1rem 8%;
  

  
}




/*.scroll_container::-webkit-scrollbar {
  display: none;
   Chrome, Safari, and Opera 
}*/


.service_item {
  flex: 0 0 calc(25% - 20px); /* Default size: 4 items visible */
  box-sizing: border-box;
  margin-bottom: 20px;
  
}

.ourservice_sh {
  padding: 8rem 0 10rem 0;
  background: url("./assets/bg_1.png") no-repeat top/cover,
    url("./assets/bg_2.png") no-repeat bottom/cover,
    url("./assets/bg_3.png") no-repeat left/contain;

  background-color: #01041c;
}

.ourservice_sh_new {
  padding: 0 0 5% 0;
  background: url("./assets/bg_1.png") no-repeat top/cover,
    url("./assets/bg_2.png") no-repeat bottom/cover,
    url("./assets/bg_3.png") no-repeat left/contain;

  background-color: #01041c;
}
.ourservice_heading_div{
  padding-left: 0%;
}

.ourservice_heading_div h1 {
  color: var(--Tailwind-white, #fff);
  font-family: Cairo;
  font-size: 80px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.8px;
  line-height: 1.5;
}

.ourservice_heading_div p {
  color: var(--Tailwind-white, #fff);
  font-family: "Open Sans";
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px;
  /* 150% */
  padding-bottom: 40px;
}

.service_item h5 {
  color: var(--Tailwind-white, #fff);
  font-family: "Open Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
  padding-top: 1.2rem;
}

.service_item p {
  color: var(--Tailwind-white, #fff);
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  /* 143.75% */
}

.service_item_img_div {
  width: 100%;
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.service_item_img_div img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.8rem;
}

/*Our service page style  - end------------------------------------------*/

/*Explore page style  - start------------------------------------------*/

.explore_sh {
  background: radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0.61) 0%, rgba(0, 0, 0, 0.52) 100%), url('./assets/explore_bgg.png') lightgray 50% / cover no-repeat;
  padding: 10rem 0;
}

.explore_sh h2 {
  color: var(--Tailwind-white, #fff);
  font-family: Cairo;
  font-size: 80px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.8px;
}

.explore_sh p {
  color: var(--Tailwind-white, #fff);
  text-align: center;
  font-family: "Open Sans";
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px;
  /* 150% */
}

.explore_sh button {
  display: inline-flex;
  padding: 20px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  background: #ffd700;

  color: var(--Tailwind-slate-950, #020617);
  font-family: "Open Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.2px;
  border: none;
  margin-top: 2.5rem;
}

/*Explore page style  - end------------------------------------------*/

/*Explore visa program page  style  - start------------------------------------------*/

.explorevisaprogram_main_div {
  background: url("./assets/visa_program_bg.png") no-repeat center/cover;
}

.explorevisaprogram_sh h2 {
  color: var(--Tailwind-slate-950, #020617);
  text-align: center;
  font-family: Cairo;
  font-size: 80px;
  font-style: normal;
  font-weight: 700;
  line-height: 83px;
  /* 103.75% */
  letter-spacing: -0.8px;
}

.explorevisaprogram_sh {
  width: 55%;
  padding: 160px 0;
}

.visa_program_img_div {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.visa_program_img_div img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin-bottom: 16px;
}

.visa_program {
  padding: 24px 0;
}

.visa_program h3 {
  color: var(--Tailwind-slate-950, #020617);
  font-family: Cairo;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 40px;
  /* 125% */
  letter-spacing: -0.32px;
}

.visa_program p {
  color: var(--Tailwind-slate-950, #020617);
  font-family: "Open Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 34px;
  /* 170% */
}

.visa_program_link {
  color: var(--Tailwind-blue-700, #1d4ed8);
  font-family: "Open Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 33px;
  /* 165% */
  letter-spacing: -0.4px;
  text-decoration: none;
}

/*Explore visa program page  style  - end------------------------------------------*/

/*Get in touch page  style  - start------------------------------------------*/

.getintouch_sh {
  background: url("./assets/getintouch_bg_01.png") no-repeat left/contain,
    url("./assets/getintouch_bg_02.png") no-repeat center/cover;

  background-color: #01041c;
  padding: 5rem 0;
}

.getintouch_sh_new {
  background: url("./assets/getintouch_bg_01.png") no-repeat left/contain,
    url("./assets/getintouch_bg_02.png") no-repeat center/cover;

  background-color: #01041c;
  padding: 0;
}

.getintouch_heading h1 {
  color: var(--Tailwind-white, #fff);
  font-family: Cairo;
  font-size: 80px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.8px;
  margin-left: 0%;
}

.getintouch_deatils p {
  color: var(--Tailwind-white, #fff);
  font-family: Cairo;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 100% */
  letter-spacing: -0.2px;
  opacity: 0.7;
}

.getintouch_deatils h6 {
  color: var(--Tailwind-white, #fff);
  font-family: Cairo;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 83.333% */
  letter-spacing: -0.24px;
}

.getintouch_details_main {
  padding: 0 12% 0 8%;
}

.getintouch_details_main_new {
  padding: 4% 12% 6% 8%;
}
.lineheight_sh {
  line-height: 34px;
}

/*Get in touch page  style  - end------------------------------------------*/

/*Why choose us page  style  - start------------------------------------------*/

.whychoose_sh {
  /* background:  url('./assets/xxx.png') no-repeat center/cover; */
  

  background-color: #FFF;
  padding: 5rem 0;
}

.whychoose_heading h1 {
  color: var(--Tailwind-slate-950, #020617);
  font-family: Cairo;
  font-size: 80px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.8px;
}
.whycoose_img_div {
  display: flex;
  width: 40px;
  height: 40px;
  padding: 5px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 12px;
  background: #ffd700;
}

.whychoose_details_main {
  padding: 0 8%;
}
.whychoose_deatils {
  width: 17%;
  display: flex; /* Enable Flexbox */
  flex-direction: column; /* Stack items vertically */
  justify-content: start; /* Center items vertically */
  align-items: start; /* Center items horizontally */
  text-align: start; /* Align text to the center */
  padding : 10px 0;

  
}
.whychoose_deatils h6 {
  color: var(--Tailwind-slate-950, #020617);
  font-family: "Open Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px; /* 138.889% */
}

.whychoose_deatils p {
  color: var(--Tailwind-slate-950, #020617);
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px; /* 143.75% */
}

/*Why choose us page  style  - end------------------------------------------*/

/*What our client page  style  - start------------------------------------------*/

.review_sh {
  padding: 7rem 0 10rem 0;
  background: url("./assets/review_bg_1.png") no-repeat center/cover,
    url("./assets/review_bg_2.png") no-repeat left/contain;

  background-color: #01041c;
}

.review_sh_new {
  padding: 0rem;
  background: url("./assets/review_bg_1.png") no-repeat center/cover,
    url("./assets/review_bg_2.png") no-repeat left/contain;

  background-color: #01041c;
}

.review_heading_div h1 {
  color: var(--Tailwind-white, #fff);
  font-family: Cairo;
  font-size: 80px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.8px;
  padding-left: 0;
}

.reviewer_img_div {
  width: 48px;
  height: 48px;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.reviewer_img_div img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.review_item {
  width: 100%;
  /* Ensure each item takes up 25% width */
  flex: 0 0 auto;
  /* Prevent shrinking or expanding */
  text-align: start;
  box-sizing: border-box;
  /* Include padding/borders in width calculation */
}
.review_item p {
  color: var(--Tailwind-white, #fff);
  font-family: "Open Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 450;
  line-height: 199.7%; /* 39.94px */
  letter-spacing: 0.2px;
}

.reviewer_div p {
  color: var(--Tailwind-white, #fff);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.scroll_container_review {
  overflow-x: auto;
  display: flex;
  /* Align items horizontally */
  gap: 3rem;
  /* Add spacing between items */
  padding: 2rem 7% 2rem 8%;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/*What our client page  style  - end------------------------------------------*/
/*Contact page  style  - start------------------------------------------*/

.contact_img_div {
  width: 50%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact_img_div img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.contactform_div h2 {
  color: var(--Tailwind-slate-950, #020617);
  font-family: Cairo;
  font-size: 80px;
  font-style: normal;
  font-weight: 700;
  line-height: 82px; /* 102.5% */
  letter-spacing: -0.8px;
}

.contactform_div p {
  color: var(--Tailwind-slate-950, #020617);
  font-family: "Open Sans";
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px; /* 150% */
  width: 88%;
}
.contact_form_heading {
  margin-bottom: 2rem;
}
.contactform_div {
  padding: 0 8%;
  width: 50%;
}

.form-label {
  color: var(--Tailwind-slate-950, #020617);
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.contact_btn {
  display: flex;
  padding: 14px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  background: #ffd700;

  color: var(--Tailwind-slate-950, #020617);

  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.16px;
  border: none;
}

.form-control, .form-select {
  background-color: #f7f7f7 !important; /* Light blue background */
  border-color: #cbd5e1 !important; /* Blue border */
  color: #333; /* Text color */
}
.form-control:focus {
  border-color: #cbd5e1 !important; /* Green border on focus */
  box-shadow: 0 0 5px #cbd5e1 !important; /* Green glow effect */
}
.form-select:focus{
  border-color: #cbd5e1 !important; /* Green border on focus */
  box-shadow: 0 0 5px #cbd5e1 !important; /* Green glow effect */
}

.footer_item_01 {
  width: 80%;
}
.footer_sh {
  padding: 4% 8% 2% 8%;
}

.footer_bottom p {
  color: var(--Tailwind-slate-950, #020617);
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 1px;
}

.footer_bottom {
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  background: #ffd700;
}

.footer_item_01 p {
  color: var(--Tailwind-white, #fff);
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  opacity: 0.8;
}
.footer_item_01_info a{
color: var(--Tailwind-white, #FFF);
font-family: "Open Sans";
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
opacity: 1;
margin-bottom: 1px;
padding-left: 5px;
}

.quick_link_div h5{
  color: var(--Tailwind-white, #FFF);
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.Link_cls_footer{
  color: var(--Tailwind-white, #FFF);
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  display: block;
  text-decoration: none;
  padding: 5px 0;
}
.footer_social_media img{
  cursor: pointer;
}
.footer_item_01_info__pointer{
  cursor: pointer;
}

.legal_div h5{
  color: var(--Tailwind-white, #FFF);
font-family: "Open Sans";
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;

}
.legal_div h6{
  color: var(--Tailwind-white, #FFF);
font-family: "Open Sans";
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
cursor: pointer;
}

.warning_div{
  width: 25%;
  display: inline-flex;
  padding: 12px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 12px;
  background: var(--Tailwind-slate-900, #0F172A);
  position: absolute;
  bottom: 60px;
  right: 20px;
}

.warning_div p{
  color: var(--Tailwind-white, #FFF);
  font-family: "Open Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
  opacity: 0.6;
  margin-bottom: 2px;
}

.footer_main_div{
 
  background: url("./assets/footer_bg_01.png") no-repeat left/cover;
  background-color: #020418;
  position: relative;
}

.country_visa_start_btn {
  display: flex;
  padding: 15px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  background: #FFD700;

  color: var(--Tailwind-slate-950, #020617);
  font-family: "Open Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.2px;
  border: none;
}

.country_content{
  padding: 7% 23.5%;
}

.country_content h3{
  color: var(--Tailwind-slate-950, #020617);
  font-family: "Open Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.country_content p{
  color: var(--Tailwind-slate-950, #020617);
  font-family: "Open Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 38px; /* 190% */
}

.country_content ul li{
  color: var(--Tailwind-slate-950, #020617);
  font-family: "Open Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 38px; /* 190% */
}

.country_content_start_btn_div button{
  display: flex;
  padding: 15px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  background: #FFD700;

  color: var(--Tailwind-slate-950, #020617);
  font-family: "Open Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.2px;
  border: none;
}














.country_visa_back_btn{
  display: inline-flex;
  padding: 8px 16px 8px 8px;
  align-items: center;
  gap: 4px;
  border-radius: 9999px;
  background: rgba(255, 255, 255, 0.40);
  backdrop-filter: blur(2px);
  color: #FFF;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border: none;
}
.country_01_home{
  
  background: url("./assets/luxembourg.png") no-repeat top/cover;
  
}
.country_02_home{
  
  background: url("./assets/germany.png") no-repeat top/cover;
  
}
.country_03_home{
  
  background: url("./assets/portugal.png") no-repeat top/cover;
  
}
.country_04_home{
  
  background: url("./assets/sweden.png") no-repeat top/cover;
  
}
.country_home {
  padding: 0 0 15rem 0;
}

.country_home h1{
  color: var(--Tailwind-white, #FFF);
  font-family: Cairo;
  font-size: 88px;
  font-style: normal;
  font-weight: 700;
  line-height: 97px; /* 110.227% */
  letter-spacing: -0.88px;
}

.country_home p{
  color: var(--Tailwind-white, #FFF);
  text-align: center;
  font-family: "Open Sans";
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px; /* 131.818% */
}
.country_visa_back_btn_div{
  padding-bottom: 6rem;
  padding-top: 1rem;
}

.hhh{
  padding-bottom: 150px;
}



@media (max-width: 1024px) {
 
.contactform_div h2 {

  font-size: 70px;
}
}

@media (max-width: 992px) {
  
  .footer_item_01 {
    width: 100%;
  }

  .warning_div{
    width: 50%;
    position: relative;
    bottom: 0px;
    right: 0px;
    margin: 20px 0 10px 0;
  }

  .footer_sh {
    padding: 4% 6% 2% 6%;
  }

  .contactform_div {
    padding: 10% 8%;
    width: 100%;
    
  }

  .contactform_div h2 {
      font-size: 60px;

  }
  
  .contactform_div p {
    font-size: 20px;
   
  }

  .explorevisaprogram_sh {
    width: 90%;
    padding: 160px 0;
  }
  .explorevisaprogram_sh h2 {
     font-size: 70px;
    line-height: 80px;
  }
  .service_item {
    flex: 0 0 calc(50% - 20px); 
  }
  .navbar_sh ul li {
  padding: 10px 0px;
  
  }
  .navbar_sh ul{
    padding-bottom:30px;

  }
  .whychoose_deatils {
    width: 100%;
    display: flex; /* Enable Flexbox */
    flex-direction: column; /* Stack items vertically */
    justify-content: center; /* Center items vertically */
    align-items: center; /* Center items horizontally */
    text-align: center; /* Align text to the center */
    padding : 20px 0;
  }
}


@media (max-width: 768px) {


/* .Toastify__toast-container {
  top: 20% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  position: fixed !important;
} */
  
  .warning_div{
    width: 100%;
  }

  .footer_sh {
    padding: 10% 5% 6% 5%;
  }

  .contactform_div {
    padding: 5% 5% 15% 5%;
    width: 100%;
    
  }
  .contactform_div h2 {
    font-size: 50px;
  }
  
  .getintouch_heading h1 {
    font-size: 50px;
    margin-left: 5%;
  }

  .getintouch_sh {
    padding: 2rem 0;
  }
  .getintouch_sh_new {
    padding: 0;
  }

  .getintouch_deatils p {
    font-size: 18px;
  }
  
  .getintouch_deatils h6 {
    font-size: 22px;
  }
  .getintouch_details_main {
    padding: 0 5% 0 8%;
  }
  .getintouch_details_main_new{
    padding: 0 5% 10% 8%;
  }
 
  .explorevisaprogram_sh h2 {
    font-size: 50px;
   line-height: 60px;
 }

 .explorevisaprogram_sh {
  
  padding: 80px 0;
}

.visa_program h3 {
  font-size: 28px;
}

.visa_program p {
  font-size: 18px;
}
.visa_program_link {
  font-size: 18px;
}

.review_item {
  width: 100%
 }
.review_item p {
  font-size: 18px;
}

.review_heading_div h1 {
  font-size: 60px;
  line-height: 80px;
  padding: 0 5%;
  
}

.review_sh {
  padding: 4.5rem 0 4rem 0;
}
.review_sh_new {
  padding:  0;
}

.country_home h1{
  font-size: 55px;
  line-height: 65px; 
}

.country_home p{
  
  font-size: 19px;
 
}
.country_visa_back_btn{
  font-size: 14px;
}

.country_content{
  padding: 7% 5% 25% 5%;
}

.whychoose_deatils {
  width: 100%;
  display: flex; /* Enable Flexbox */
  flex-direction: column; /* Stack items vertically */
  justify-content: center; /* Center items vertically */
  align-items: center; /* Center items horizontally */
  text-align: center; /* Align text to the center */
  padding : 20px 0;
}
.whychoose_heading h1 {
  font-size: 50px;
  line-height: normal;
  padding-left: 5%;
  
}
.reviewer_div p {
  font-size: 14px;
}

.explore_sh {
  padding: 5rem 0;
}

.explore_sh h2 {
  font-size: 60px;
  line-height: 70px;
}

.explore_sh p {
 
  font-size: 20px;
  line-height: 30px;
}
.explore_sh button {
  padding: 19px 24px;
  font-size: 18px;
}
.service_item {
  flex: 0 0 calc(100% - 20px); 
}
.ourservice_heading_div{
  padding-left: 5%;
}

.ourservice_heading_div h1 {
  font-size: 60px;
  line-height: 80px;
  
  
}

.ourservice_heading_div p {
  font-size: 20px;

}

.whoweare_sh {
  background-color: #fff;
  padding: 80px 0px 70px 0;
  width: 90%;
}

.whoweare_sh h2 {
  font-size: 60px;
  line-height: 70px;
}

.whoweare_sh p {
  color: var(--Tailwind-slate-950, #020617);
  font-family: "Open Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px;
  margin-bottom: 30px;
}

.explore__image_main_div{
  padding: 0 5% 70px 5% ;

}

.home_sh h2 {
  font-size: 40px;
}

.home_sh h1 {
  font-size: 65px;
  line-height: 75px;
}

.home_sh p {
  font-size: 20px;
}

.get_free_btn {
  padding: 18px 24px;
}

.about_btn {
  padding: 18px 24px;
}
.btn_div_sh{
  padding: 0 5%;
}
.header_shh{
  padding: 0px 0 0px 0;
}
.nav_sh {
  border-radius: 0px;
  background: rgba(2, 6, 23, 0.68);
  box-shadow: 0px 2px 72px 0px rgba(255, 255, 255, 0.14);
}

.ddd{
  padding-bottom: 0rem;
}
.ourservice_sh {
  padding: 4rem 0 4rem 0;
  background: url("./assets/bg_1.png") no-repeat top/cover,
    url("./assets/bg_2.png") no-repeat bottom/cover,
    url("./assets/bg_3.png") no-repeat left/contain;

  background-color: #01041c;
}

}


@media (min-width: 768px) {
  .btn_div_sh {
    flex-direction: row; 
    padding: 20px;/* Change to horizontal layout on larger screens */
  }

  .get_free_btn, .about_btn {
    width: auto; /* Allow buttons to size naturally on larger screens */
    
  }
}


@media (max-width: 768px) {
  .header_shh {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9999;
    background-color: #01041c;
  }
  body {
    margin: 0;
    padding-top: 70px; /* Offset for the fixed header */
  }
}

@media (min-width: 769px) {
  
  body {
    margin: 0;
    padding-top: 0px; /* Offset for the fixed header */
  }
}


.header_shh {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
  background-color: #01041c;
 
}
body {
  margin: 0;
  padding-top: 70px; /* Offset for the fixed header */
}

.ddd{
  padding-bottom: 8rem;
}

/* Change the color of the navigation buttons */
.swiper-button-next,
.swiper-button-prev {
  color: #FFF !important; /* Set the color to black */
  top: 40% !important; /* Align vertically */
  transform: translateY(-50%) !important; /* Center vertically */
}

/* Change the color of the pagination dots */
.swiper-pagination-bullet {
  background-color: #FFF !important;
  
}

/* Optional: Change the color of the active pagination dot */
.swiper-pagination-bullet-active {
  background-color: #FFF !important; /* Active dot color */
  opacity: 1; /* Ensure full visibility */
}

.review_buttons .swiper-button-next {
  color: #FFF !important; /* Set the color to black */
  top: 40% !important; /* Align vertically */
  transform: translateY(-50%) !important; /* Center vertically */
  display: none !important;
}

.review_buttons .swiper-button-prev {
  color: #FFF !important; /* Set the color to black */
  top: 40% !important; /* Align vertically */
  transform: translateY(-50%) !important; /* Center vertically */
  display: none !important;
}



.loading-placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Full page height */
  background-color: #f5f5f5; /* Light background */
  font-family: Arial, sans-serif;
  color: #555; /* Grey text color */
}

.spinner {
  width: 50px;
  height: 50px;
  border: 6px solid #ddd; /* Light grey border */
  border-top: 6px solid #01041c; /* Blue top border for animation */
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 20px; /* Space between spinner and text */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}




/*............................................................*/
/*............................................................*/
/*............................................................*/
/*............................................................*/

.privacy{
  padding: 0 8%;
  color: #020617;
  font-family: "Open Sans";
  text-align: justify;
  
}

.privacy h1{
  font-weight: 700;
  font-size: 3rem;

}

.privacy h4{
  font-size: 1rem;
  font-weight: 500;
  
}


.privacy ol li{
  font-weight: 700;
  line-height: normal;
  padding-bottom: 1rem;
  
}

.privacy ul li {
  font-weight: 400;
  line-height: 1.8rem;
}

.privacy span {
  font-weight: 600;
}

@media (max-width: 768px) {
  .privacy{
    padding: 0 5%;
    text-align: start;
    
  }
  .privacy h1{
    font-weight: 700;
    font-size: 2rem;
    padding-bottom: 2rem;
  
  }
}





















