.modal-dialog {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;
  }
.modal_main{
    padding: 10px 10px;
}

.box_content_heading{
    color: var(--Tailwind-slate-950, #020617);
leading-trim: both;
text-edge: cap;
font-family: "Open Sans";
font-size: 1.5rem;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.phra{
    color: var(--Tailwind-slate-600, #475569);
    padding: 0 30px;
    font-family: "Open Sans";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 165.4%; /* 1.44725rem */
}
.note{
    color: var(--Tailwind-slate-600, #475569);
leading-trim: both;
text-edge: cap;
font-family: "Open Sans";
font-size: 0.75rem;
font-style: normal;
font-weight: 400;
line-height: 172.4%; /* 1.293rem */
}
.dotted_line{
    border: 0;
  border-top: 2px dashed #CBD5E1;
  margin-top: 2px;
}
.box_btn{
    border-radius: 0.625rem;
background: var(--Medium-Red-Violet-950, #020617);
display: flex;
height: 3rem;
padding: 0.75rem 1.5rem;
justify-content: center;
align-items: center;
gap: 0.5rem;
color:white
}

             
.night_data{
    color: var(--Tailwind-slate-950, #020617);
leading-trim: both;
text-edge: cap;
font-family: "Open Sans";
font-size: 0.75rem;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.list_content{
    color: var(--Tailwind-slate-950, #020617);
leading-trim: both;
text-edge: cap;
font-family: "Open Sans";
font-size: 0.875rem;
font-style: normal;
font-weight: 600;
line-height: normal;

}


@media (max-width: 576px) {
    .modal-dialog {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh; /* Full viewport height for mobile */
        width: 100vw;  /* Full viewport width for mobile */
        margin: 0;
        padding: 5px;
    }

    .box_content {
        /* Adjust any additional styling for mobile here */
    }}